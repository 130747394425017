:root {
  --backgroundColor: #ffffff;
  --backgroundSecondaryColor: #212121;
  --letterColor: #000;
  --hcLetterColor: #000;
  --linksColor: #000;
  --primary: #0150a8;
  --secondary: #212121;
  --title: #deec00;
  --colorText: white;
}

* {
  margin: 0%;
  padding: 0%;
  color: var(--letterColor);
}

body {
  background-color: var(--backgroundColor);
}

ul,
li {
  text-decoration: none;
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--linksColor);
}

.d-flex {
  display: flex;
}

.justify {
  justify-content: space-evenly;
}

.centroMosaico {
  margin-left: 40px;
}
