@import "basic.css";

.banner {
  width: 100%;
}

.banner__img {
  width: 100%;
  height: 15vh;
}

.banner--info {
  position: absolute;

  top: 50%;
  transform: translate(0, -50%);
  color: var(--hcLetterColor);
  background: #000;
}
