@import "basic.css";

.footer {
  background-color: var(--backgroundSecondaryColor);
  display: inline-flex;
  width: 100%;
}

.footer--card {
  width: 25%;
}

.footer--card__title {
  color: var(--title);
}

.footer--card__input {
  padding: 0.5%;
}

.footerEStilo {
  color: #deec00;
  margin-top: 20px;
  margin-bottom: 20px;
}

.textoFooter {
  color: var(--colorText);
}
