@import "basic.css";

.card {
  width: 20%;
}

.card__img {
  width: 90%;
  justify-self: center;
}

.card__title {
  color: var(--primary);
}

.coleccion {
  color: rgb(23, 73, 211);
  text-align: center;
  border-top: 30px solid white;
  border-bottom: 30px solid white;
}
